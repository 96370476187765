import axios from "axios";

const baseURL = process.env.REACT_APP_BACKEND_URL || "/api";

export default axios.create({
    baseURL: baseURL,
    responseType: 'json',
    timeout: 10000,
    // Uncomment if credentials (like cookies) need to be sent across domains
    // withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});
